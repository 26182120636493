import React from 'react';
import { Layout } from '../components/Layout';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import styled from 'styled-components';
import { SEO } from '../components/SEO';
import { SectionHeader } from '../components/SectionHeader';
import { CodeHighlight } from '../components/CodeHighlight';

const StyledLayoutWrapper = styled.div`
  padding: 0 10px;
  max-width: 100%;
`;

const StyledSectionHeader = styled(SectionHeader)`
  margin-bottom: 50px;
`;

interface Props {
  pageContext: any;
}

const Page = ({ pageContext }: Props) => {
  const { node: page } = pageContext.page;
  return (
    <Layout>
      <SEO title={page.title} />
      <StyledLayoutWrapper>
        <StyledSectionHeader>{page.title}</StyledSectionHeader>

        <MDXProvider components={{ code: CodeHighlight }}>
          <MDXRenderer>{page.body}</MDXRenderer>
        </MDXProvider>
      </StyledLayoutWrapper>
    </Layout>
  );
};

export default Page;
